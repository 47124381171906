import React from "react"
import { Link, graphql } from "gatsby"
import { Container } from "react-bootstrap"
import Img from "gatsby-image"

import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"

const AboutOadrPrograms = props => {
  return (
    <Layout>
      <SEO title="OpenADR Program Database" />
      <div>
        <div className="page-header">
          <Container>
            <h1 style={{ padding: "40px" }}>
              OpenADR load shifting programs and device rebates
            </h1>
          </Container>
        </div>
      </div>
      <div className="divider-image">
        <Img fixed={props.data.icon.image.fixed} alt="" />
      </div>
      <Container style={{ maxWidth: "600px" }}>
        <h4 style={{ textAlign: "center", marginBottom: "20px" }}>About the OpenADR Programs</h4>
        <p>
          The goal of this list is to help companies with load shifting capacity
          and OpenADR certification learn where they can use it and how much
          they will be compensated. The list does not include all load shifting
          programs, just those that require or use OpenADR.
        </p>
        <p>
          Unlike other lists of OpenADR deployments (e.g.{" "}
          <a
            href="https://drrc.lbl.gov/openadr-deployments"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{" "}
          and{" "}
          <a
            href="http://grid4home.com/openadrmap/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          ), this list only includes currently available programs, not pilots.
          However, some "pilot" programs are included due to their huge scale
          and potential for ongoing enrollment (e.g. SCE's Charge Ready).
        </p>
        <p>
          The list includes two basic categories of incentives:{" "}
          <strong>device rebates</strong> and{" "}
          <strong>load shifting incentives</strong>. Some programs offer both,
          but most are one or the other.
        </p>
        <p>
          <strong>Device rebates</strong> are payments to offset the price of
          purchase by an end customer, which may be a commercial building,
          residential customer, etc. Devices that qualify for rebates can
          therefore have some of the purchase price covered by the utility, an
          excellent value proposition for both buyer and seller. Often, the
          utility requires a device to be OpenADR compliant before they will
          provide a rebate to make sure that device can be available to shift
          load in the near future in one of their load shifting programs.
        </p>
        <p>
          <strong>Load shifting</strong> incentives are an ongoing reward for
          providing load shifting capacity into a program. Incentive amounts are
          usually scaled based on the amount of load shifted and are paid out on
          a monthly or yearly basis. These programs can be bid into directly by
          an end customer, but more often a company called an aggregator acts as
          a middleman to work with the end customer and connect them into the
          utility program. The aggregator could be the device manufacturer or a
          third party company. These can be a good source of income for all
          types of end customers: larger commercial buildings may make thousands
          of dollars a year by participating in these programs, and larger
          campuses can make much more.
        </p>
        <p>
          If you have any questions about the programs or additions to the list,
          please <Link to="/about#contact">contact us</Link>.
        </p>
        <div style={{ textAlign: "center" }}>
          <Link to="/oadr-programs">Go to list of programs</Link>
        </div>
      </Container>
    </Layout>
  )
}

export default AboutOadrPrograms

export const aboutTableQuery = graphql`
  query {
    icon: contentfulWebsiteImages(imageId: { eq: "gridfabricMiniIcon" }) {
      image {
        fixed(width: 30) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
    }
  }
`
